import { gsap } from "gsap";

const magnifierFollow = () => {
    const magItems = document.querySelectorAll('.magnifier-wrapper');
    magItems.forEach((item)=> {
        let mover = item.querySelector('.magnifier');
        let offsets = mover.clientHeight / 2;
        let moverImage = mover.querySelector('.mag-image-wrapper');
        let scale = moverImage.clientHeight / item.clientHeight;

        item.addEventListener('mouseenter', ()=>{
            gsap.to(mover, {
                duration: 0.5,
                scale: 1,
                opacity: 1,
                ease: "Power3.easeOut",
            });
        });

        item.addEventListener('mouseleave', ()=>{
            gsap.to(mover, {
                duration: 0.5,
                scale: 0,
                opacity: 1,
                ease: "Power3.easeOut",
            });
        });
        item.addEventListener('mousemove', (e)=>{
            let pos = item.getBoundingClientRect();
            let l = e.clientX - pos.left;
            let t = e.clientY - pos.top;
            mover.style.left = `${l - offsets}px`;
            mover.style.top = `${t - offsets}px`;
            moverImage.style.left = `${-l * scale + offsets}px`;
            moverImage.style.top = `${-t * scale + offsets}px`;
        });
    });
}

export { magnifierFollow };