import * as React from "react"
import { useEffect } from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Seo from "../../components/seo"

import { projects } from "../../components/data/projects"

import { StaticImage } from "gatsby-plugin-image"

import ProjectHeader from "../../components/project-header/project-header"
import ReadNext from "../../components/read-next/read-next"
import Index from "../../components/index"
import { RoughNotation } from "react-rough-notation"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

import ScanningMov from 'file-loader!../../images/projects/high-museum/Scanning.mov'
import CommentsMov from 'file-loader!../../images/projects/high-museum/Comments.mov'
import OnboardingMov from 'file-loader!../../images/projects/high-museum/Onboarding.mov'
import InfoTagsMov from 'file-loader!../../images/projects/high-museum/Info Tags.mov'
import InfoMov from 'file-loader!../../images/projects/high-museum/Info.mov'
import PostcardsMov from 'file-loader!../../images/projects/high-museum/Postcards.mov'
import { magnifierFollow } from "../../components/utils/magnifier"

const theme = projects['highMuseum'].themeColor;

const ProjectPage = () => {

  const chapters = [
    {
      name: `Overview`,
      scrollTo: 'overview',
    },
    {
      name: 'Research',
      scrollTo: 'research',
    },
    {
      name: 'Ideation',
      scrollTo: 'ideation',
    },
    {
      name: 'Design',
      scrollTo: 'design',
    },
    {
      name: 'Prototype',
      scrollTo: 'prototype',
    },
    {
      name: 'Evaluation',
      scrollTo: 'evaluation',
    },
  ];

  useEffect(()=>{
      magnifierFollow();
  });

  return (
  <>
    <Seo title="High Museum of Art" />
    <ProjectHeader projectObj={projects['highMuseum']} />
    <section className={`fix-top fix-bottom`} id={`overview`}>
      <Index chapters={chapters} />
      <div className={`content--md-center`}>
        <h6 style={{ color: theme}}>Overview</h6>
        <h2 className={`add-margin-bottom`}>Objective</h2>
        <p className={`primary lh`}>Located in the city of Atlanta, the High Museum of Art is one of the leading art museums in the Southeastern United States. It boasts a celebrated collection of artworks from different regions and cultures. However, exploring the 15,000+ works of art in its permanent collection comes with its own set of problems for the museum visitors. With this project, we identify problems with visitors' experience at the museum and suggest design solutions to improve their engagement with different artworks.</p>
        <div className={`add-margin-top`}>
          <h6 className={`center`} style={{ color: theme}}>Problem</h6>
          <h4 className={`center`}>How to help individual visitors be more engaged during their visit to the <RoughNotation type={`underline`} show color={theme} multiline>High Museum of Art</RoughNotation> in Atlanta?</h4>
        </div>
      </div>
    </section>
    <section className={`fix-top fix-bottom`}>
      <div className={`content--md-center`}>
        <h2 className={`add-margin-bottom`}>Timeline</h2>
        <div className={`project-image remove-margin`}>
            <StaticImage
              src={'../../images/projects/high-museum/Timeline.png'}
              width={1200}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Storyboarding"
              loading={`eager`}
            />
          </div>
      </div>
    </section>
    <section className={`fix-top fix-bottom`} id={`research`}>
      <div className={`content--md-center`}>
        <h2>Research</h2>
        <p className={`primary add-margin-bottom`}>To answer our questions about hidden problems for the target user group, stakeholders, and to identify opportunities for a solution, we devised a research plan:</p>
        <h4>Field Trip</h4>
        <div className={`project-split-wrapper add-margin-bottom`}>
          <div className={`split`}>
            <p className={`primary`}>During the group's field trip to the High Museum of Art, we tried to understand and situate this issue within the context of the High. We made several observations:</p>
            <ul>
              <li>The prompts provide negligible information about the significance of the art piece</li>
              <li>There is little context about why some pieces of art are place together</li>
              <li>Accompanied visitors would explain and share thoughts on artworks with each other</li>
              <li>Due to the COVID-19 pandemic, there are no audio guides or paper guides provided by the museum and services such as guided tours have become very limited.</li>
            </ul>
          </div>
          <div className={`split`}>
            <div className={`content`}>
              <div className={`project-image remove-margin`}>
                <StaticImage
                  src={'../../images/projects/high-museum/Field Trip 1.png'}
                  width={1200}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Affinity Map"
                  loading={`eager`}
                />
              </div>
              <div className={`project-image min-margin`}>
                <StaticImage
                  src={'../../images/projects/high-museum/Field Trip 2.png'}
                  width={1200}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Affinity Map"
                  loading={`eager`}
                />
              </div>
            </div>
          </div>
        </div>
        <h4>Research Questions</h4>
        <div className={`project-split-wrapper add-margin-bottom`}>
        <div className={`split`}>
        <p className={`primary`}>With our research methods, we try to answer the following questions to guide our design process:</p>
        <blockquote>
        <p className={`primary`}>1. What are the different reasons for a visitor to visit the High museum</p>
        <p className={`primary`}>2. What are their expectations regarding the visit</p>
        <p className={`primary`}>3. What are the visitors satisfied/disatisfied with?</p>
        <p className={`primary`}>4. How do visitors currently prefer to engage with artworks?</p>
        <p className={`primary`}>5. How do social aspects/presence of other visitors influence the visiting experience?</p>
        </blockquote>
        </div>
        <div className={`split`}>
          <div className={`content`}>
          <div className={`project-image min-margin`}>
                <StaticImage
                  src={'../../images/projects/high-museum/Stakeholders.png'}
                  width={1200}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Affinity Map"
                  loading={`eager`}
                />
          </div>
          </div>
        </div>
        </div>
        <h4>Findings</h4>
        <p className={`primary`}>After conducting surveys, semi-structured interviews, and contextual enquiries, we organized our findings into an affinity map. From this, we created user personas and empathy maps to characterize patterns of user behavior and needs.</p>
        <div className={`project-image hide-cursor`}>
          <div className={`magnifier-wrapper`}>
            <StaticImage
              src={'../../images/projects/high-museum/Affinity Map.jpg'}
              width={1200}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Affinity Map"
              loading={`eager`}
            />
            <div className={`magnifier`}>
              <div className={`mag-image-wrapper`}>
                <div className={`content--md`}>
                <StaticImage
                  src={'../../images/projects/high-museum/Affinity Map.jpg'}
                  width={2400}
                  quality={95}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Affinity Map"
                  loading={`eager`}
                />
                </div>
              </div>
            </div>
          </div>
            <p className={`caption`}>Affinity Map</p>
        </div>
        </div>
      </section>
      <section className={`fix-top fix-bottom`} id={`ideation`}>
        <div className={`content--md-center`}>
          <h6 style={{ color: theme}}>Ideation</h6>
          <h2>Design Concepts and Reviews</h2>
          <div className={`project-image`}>
            <StaticImage
              src={'../../images/projects/high-museum/Storyboarding.png'}
              width={1200}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Storyboarding"
              loading={`eager`}
            />
          </div>
          <h4>Sketches</h4>
          <p className={`primary add-margin-bottom`}>Sketches were a way to visualize the design ideas our team brainstormed and gather initial thoughts from user groups. Using these, we were able to receive feedbacks on the concepts and how the users perceive such ideas in the context of a museum visit.</p>
          <div className={`project-image`}>
            <StaticImage
              src={'../../images/projects/high-museum/Sketches.png'}
              width={1200}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Storyboarding"
              loading={`eager`}
            />
          </div>
          <h4>Wireframes</h4>
          <p className={`primary`}>With wireframes, we were able to represent more information such as user flow and basic terminologies to our testing user group while maintaining a low fidelity design for quick and iterative design improvements. We were able to scope out further limitations with the design at this stage and learnt about considerations for future stages of the design process. Some of our learnings from the feedback sessions were as follows:</p>
          <ul>
            <li>Identified features/concepts that did and did not align user goals.</li>
            <li>Learnt improvements on terminologies and information architecture, as we understood what best fits the user groups' mental model.</li>
            <li>Identifited users' expectations from features like scanning and information, and incorporated that in our design.</li>
            <li>Discovered navigation issues through feedback on our wireframes and improved on that with high fidelity designs.</li>
          </ul>
          <div className={`project-image`}>
            <StaticImage
              src={'../../images/projects/high-museum/Wireframes.png'}
              width={1200}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Storyboarding"
              loading={`eager`}
            />
          </div>
      </div>
    </section>
    <section className={`high-museum`} id={`design`}>
      <div className={`content--md-center`}>
        <h6 style={{ color: theme}}>Design</h6>
        <h2>Features in Action</h2>
        <div className={`project-grid-wrapper`}>
          <div className={`project-grid`}>
            <div className={`video-wrapper`}>
              <video autoPlay loop mute>
                <source src={ScanningMov} />
              </video>
            </div>
            <h4 className={`center`}>Scanning Artworks</h4>
            <p className={`center lh`}>Visitors can scan an artwork to learn more about it by simply pointing their phone camera towards the artwork. The app would recognize the artwork and offer different insights to the visitor.</p>
          </div>
          <div className={`project-grid`}>
            <div className={`video-wrapper`}>
              <video autoPlay loop mute>
                <source src={OnboardingMov} />
              </video>
            </div>
            <h4 className={`center`}>Onboarding</h4>
            <p className={`center lh`}>Post the first scan, the user can choose to read a quick onboarding guide and browse through different functionalities.</p>
          </div>
          <div className={`project-grid`}>
            <div className={`video-wrapper`}>
              <video autoPlay loop mute>
                <source src={InfoTagsMov} />
              </video>
            </div>
            <h4 className={`center`}>Info Tags</h4>
            <p className={`center lh`}>Info tags represent information related to different parts of an artwork that a visitor can learn about in more detail. It allows the visitor to notice small details about the particular artwork and learn about its significance.</p>
          </div>
          <div className={`project-grid`}>
            <div className={`video-wrapper`}>
              <video autoPlay loop mute>
                <source src={InfoMov} />
              </video>
            </div>
            <h4 className={`center`}>Information</h4>
            <p className={`center lh`}>With more a detailed information section, visitors can learn about basic details such as era, style, artist, as well as comments from experts such as art professors or museum curators. This section also allows visitors to browse through related artworks present at the High Museum and informs them about how they are related.</p>
          </div>
          <div className={`project-grid`}>
            <div className={`video-wrapper`}>
              <video autoPlay loop mute>
                <source src={CommentsMov} />
              </video>
            </div>
            <h4 className={`center`}>Comments</h4>
            <p className={`center lh`}>For individual visitors, we learnt that they would like to interact with other visitors and discuss opinions about different artworks. The comments section, built with profanity checks, allows visitors to learn about other visitors' perspective on different artworks</p>
          </div>
          <div className={`project-grid`}>
            <div className={`video-wrapper`}>
              <video autoPlay loop mute>
                <source src={PostcardsMov} />
              </video>
            </div>
            <h4 className={`center`}>Postcards</h4>
            <p className={`center lh`}>After the visit, people can share their favorite pieces of art with their friends and family. The postcards feature allows visitors to find good quality photos of their favorite artworks, customize it, and share with others through social media and messaging.</p>
          </div>
        </div>
      </div>
    </section>
    <section className={`fix-bottom`} id={`prototype`}>
    <div className={`content--md-center`}>
      <div className={`project-split-wrapper`}>
        <div className={`split`}>
          <h6 style={{ color: theme}}>Interactions</h6>
          <h2>Prototype <span className={`icon-lg`}><FontAwesomeIcon icon={faArrowRight} /></span></h2>
          <p className={`primary margin-bottom`}>To represent the visual design and interaction concepts of all the features, we built a high (no pun intended) fidelity prototype using Figma, embedded here in this section. Click or Tap on the app icon to start exploring the app's prototype for yourself!</p>
        </div>
        <div className={`split center-content hide-cursor`}>
          <div>
          <iframe className={`figma-embed`} width={`100%`} height={600} src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FDaelMmQymNjRZOcPYmVyj9%2FHigh-Museum-Visitor-App%3Fpage-id%3D0%253A1%26node-id%3D594%253A8213%26viewport%3D241%252C48%252C0.47%26scaling%3Dscale-down%26starting-point-node-id%3D594%253A8213%26hide-ui%3D1" allowfullscreen></iframe>
          <div className={`project-image remove-margin`}><p className={`caption remove-margin`}>Interactive Prototype</p></div>
          </div>
        </div>
      </div>
    </div>
    </section>
    <section className={`fix-top`} id={`evaluation`}>
      <div className={`content--md-center`}>
        <h6 style={{ color: theme}}>Final thoughts and Learnings</h6>
        <h2>Evaluation</h2>
        <p className={`primary margin-bottom`}>
          As a research centered project, designing for museum visitors was a creative and challenging experience. Though every step of the process contributed to the final design, I discovered that the interactive feedback sessions with the target users gave us a much deeper understanding of our own work. As a team, we've decided to further improve on some of the shortcomings and potentially develop this app based on our design.
        </p>
      </div>
    </section>
    <ReadNext projectObj={projects['aesop']} />
  </>
  )
}

export default ProjectPage